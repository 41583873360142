<template lang="">
    <div>
      <h6 class="heading-small text-muted mb-4">ข้อมูลลูกค้า</h6>
      <div class="pl-lg-4">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-4">
                  <label class="form-label optional">ชื่อ</label>
                  <base-input name="name" placeholder="ชื่อ" v-model="item.name"></base-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-4">
                  <label class="form-label">เบอร์โทรศัพท์</label>
                  <base-input name="phone" :rules="{required: true}" placeholder="เบอร์โทรศัพท์" v-model="item.phone"></base-input>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-4">
                  <label class="form-label optional">อีเมล</label>
                  <base-input type="email" name="email" placeholder="อีเมล" v-model="item.email"></base-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-4">
                  <label class="form-label optional">ไลน์</label>
                  <base-input name="line" placeholder="ไลน์" v-model="item.line"></base-input>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-4">
              <label class="form-label optional">ที่อยู่จัดส่ง</label>
              <b-form-textarea name="address" placeholder="ที่อยู่จัดส่ง" v-model="item.address" rows="3" max-rows="5"></b-form-textarea>
              <base-input name="address" v-model="item.address" class="custom-input-valid-datepicker"></base-input>
            </div>
          </div>

          <div class="col-12">
            <hr class="my-4">
          </div>
          <div class="col-sm-12">
            <h6 class="heading-small text-muted mb-4">ข้อมูลออกใบกำกับภาษี/ใบเสร็จรับเงิน</h6>
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="mb-4">
                  <label class="form-label">ชื่อผู้ติดต่อ</label>
                  <base-input name="contact" placeholder="ชื่อผู้ติดต่อ" v-model="item.contact" :rules="{required: true}"></base-input>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="mb-4">
                  <label class="form-label optional">ชื่อบริษัทตามใบเสร็จรับเงิน</label>
                  <base-input name="company_name" placeholder="ชื่อบริษัทตามใบเสร็จรับเงิน" v-model="item.company_name"></base-input>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="mb-4">
                  <label class="form-label optional">หมายเลขผู้เสียภาษี</label>
                  <base-input name="tax_id" placeholder="หมายเลขผู้เสียภาษี" :rules="{max: 15}" v-model="item.tax_id" max="10" v-number-only></base-input>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="mb-4">
                    <label class="form-label optional">เบอร์โทรศัพท์</label>
                    <base-input name="billing_phone" :rules="{min: 10, max: 10}" placeholder="เบอร์โทรศัพท์" v-model="item.billing_phone" min="10" max="10" v-number-only></base-input>
                  </div>
              </div>
              <div class="col-lg-8 col-md-6">
                <div class="mb-4">
                  <label class="form-label optional">ที่อยู่ตามใบกำกับภาษี/ใบเสร็จรับเงิน</label>
                  <b-form-textarea name="billing_address" placeholder="ที่อยู่ตามใบกำกับภาษี/ใบเสร็จรับเงิน" v-model="item.billing_address" rows="3" max-rows="5"></b-form-textarea>
                  <base-input name="billing_address" v-model="item.billing_address" class="custom-input-valid-datepicker"></base-input>
                </div>
              </div>
            </div>

          </div>



          <div class="col-12">
            <hr class="my-4">
          </div>

          <div class="col-12">
            <div class="mb-4">
              <label class="form-label optional">หมายเหตุ</label>
              <b-form-textarea name="address" placeholder="หมายเหตุ" v-model="item.remark" rows="3" max-rows="5"></b-form-textarea>
            </div>
          </div>
          <div class="col-12">
            <hr class="my-4">
          </div>
          <div class="col-sm-12">
            <h6 class="heading-small text-muted mb-4">ข้อมูลเครดิตลูกค้า</h6>
            <div class="row">
              <div class="col-md-4">
                <div class="mb-4">
                  <label class="form-label">ประเภทลูกค้า</label>
                  <Select2 v-model="item.customer_type" :options="customerTypes" :settings="{allowClear: true}" placeholder="ประเภทลูกค้า"/>
                  <base-input name="customer_type" v-model="item.customer_type" class="custom-input-valid-datepicker" :rules="{required: true}"></base-input>
                </div>
              </div>
              <div class="col-md-4">
                <label class="form-label">สถานะเครดิต</label>
                <Select2 v-model="item.credit_status" :options="customerCreditTypes" :settings="{allowClear: true}" placeholder="สถานะเครดิต"/>
              </div>
              <div class="col-md-4">
                <label class="form-label">จำนวนเครดิตที่อนุญาต</label>
                <base-input name="credit_line" v-decimal-format :rules="{required: item.credit_status==1}" placeholder="จำนวนเครดิตที่อนุญาต" v-model="item.credit_line"></base-input>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-4">
      </div>
    </div>
</template>
<script>
import Constants from '../../../util/constants';
export default {
  name: 'customer-view-form-customer',
  data () {
    return {
      customerTypes: [],
      customerCreditTypes: []
    }
  },
  props: [
    'item'
  ],
  mounted() {
    this.customerTypes = Constants.customer_types;
    this.customerCreditTypes = Constants.customer_credit_types;
  }
}
</script>
